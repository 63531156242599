/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDYMBJyLAkAmGwy_u4B63GpeogMTTgy1AE",
  "appId": "1:880757732750:web:28a30d2bd54dd4f62760df",
  "authDomain": "schooldog-i-greene-al.firebaseapp.com",
  "measurementId": "G-G08F6GL1EW",
  "messagingSenderId": "880757732750",
  "project": "schooldog-i-greene-al",
  "projectId": "schooldog-i-greene-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-greene-al.appspot.com"
}
